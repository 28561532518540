@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;600;700;800;900&display=swap');

html,
body {
  box-sizing: border-box;
  width: 100%;
  background: #f6f6f6 !important;
  min-width: 320px;
}

/*SLIDER*/
.slick-slide.slick-current img {
  transform: scale(1);
}
.slick-slide.slick-current img {
  margin-left: auto;
  margin-right: auto;
}
.slick-slide .slider-element-wrapper {
  border: solid 4px transparent;
  overflow: hidden;
}
.slick-slide.slick-current .slider-element-wrapper {
  opacity: 1;
  border: solid 4px #01b7d7;
  position: relative;
}
.slick-slide.slick-current .slider-element-wrapper:before  {
  content: '';
  display: block;
  width: 30px;
  height: 30px;
  background: #01b7d7;
  transform: rotate(45deg);
  position: absolute;
  bottom: -13px;
  z-index: -1;
  right: 120px;
}
.slick-slide.slick-active.slick-cloned.slick-current{
  outline: none;
}
.switch {
  background: #000;
}
.slider {
  max-width: 1024px;
  margin: 0 auto;
  border: 1px solid;
  position: relative;
}

.img-box {
  padding: 0;

}
.img-box img {
  width: 100%;
}

.slider-element {
 border-radius: 0px;
}

.slick-arrow.slick-prev:before{
  content: '';
  display: block;
  background: url('../assets/img/arrow-left.svg') no-repeat;
  width: 16px;
  height: 30px;
}
.slick-arrow.slick-next:before{
  content: '';
  display: block;
  background: url('../assets/img/arrow-right.svg') no-repeat;
  width: 16px;
  height: 30px;
}
.slick-prev, .slick-next{
  height: 30px;
  top: 40% !important;
}
.slick-next {
  right: -35px;
}
.slick-prev {
  left: -30px;
}
.slick-slide.slick-active.slick-cloned{
  outline: none
}

.testimonial .slick-prev:before{
  content: '';
  display: block;
  height: 90px;
  width: 65px;
  background: url('../assets/img/moms-arrow-left.svg') no-repeat;
}
.testimonial .slick-next:before{
  content: '';
  display: block;
  height: 90px;
  width: 65px;
  background: url('../assets/img/moms-arrow-right.svg') no-repeat;
}
.testimonial .slick-prev{
  left: -105px;
}
.testimonial .slick-next{
  right: -80px;
}
@media screen and (max-width: 1280px) {
  .testimonial .slick-next:before{
    height: 90px;
    width: 65px;
  }
  .testimonial .slick-prev:before{
    height: 90px;
    width: 65px;
  }
  .testimonial .slick-prev{
    left: -105px;
  }
  .testimonial .slick-next{
    right: -80px;
  }
}
@media screen and (max-width: 1125px) {
  .testimonial .slick-next:before{
    height: 85px;
    width: 60px;
  }
  .testimonial .slick-prev:before{
    height: 85px;
    width: 60px;
  }
  .testimonial .slick-prev{
    left: -105px;
  }
  .testimonial .slick-next{
    right: -80px;
  }
}
@media screen and (max-width: 1023px) {
  .testimonial .slick-next:before{
    height: 80px;
    width: 55px;
  }
  .testimonial .slick-prev:before{
    height: 80px;
    width: 55px;
  }
  .testimonial .slick-prev{
    left: -101px;
  }
  .testimonial .slick-next{
    right: -80px;
  }
}
@media screen and (max-width: 575px) {
  .testimonial .slick-next:before{
    height: 60px;
    width: 45px;
  }
  .testimonial .slick-prev:before{
    height: 60px;
    width: 45px;
  }
  .testimonial .slick-prev{
    left: -40px;
    top: 18vw !important;
  }
  .testimonial .slick-next{
    right: -30px;
    top: 18vw !important;
  }
}

@media screen and (max-width: 380px) {
  .testimonial .slick-prev{
    top: 14vw !important;
  }
  .testimonial .slick-next{
    top: 14vw !important;
  }
}


.currentWhyUs .slick-dots{
  bottom: -100px !important;
}

.currentWhyUs .slick-dots li.slick-active button:before {
  opacity: .75;
  color: #ffffff;
}
.currentWhyUs .slick-dots li button:before {
  color: #ffffff;
}
.currentWhyUs .slick-prev:before{
  content: '';
  display: block;
  width: 17px;
  height: 29px;
  background: url('../assets/img/left.svg');
}
.currentWhyUs .slick-next:before{
  content: '';
  display: block;
  width: 17px;
  height: 29px;
  background: url('../assets/img/right.svg');
}
.currentWhyUs .slick-prev, .slick-next{
  top: 25%
}
.currentWhyUs .slick-prev{
  left: -10px;
}
.currentWhyUs .slick-next{
  right: -10px;
}

/*play button in video player*/
.vjs-big-play-button {
  position: absolute;
  top: 10px;
  bottom: 10px;
  left: 10px;
  right: 10px;
  margin: auto;
}
.vjs-project-file .vjs-control-bar {
  background-color: transparent;
  bottom: 64px;
}

video[poster]{
  object-fit: cover;
}

.vjs-poster {
  background-size: contain;
  background-color: transparent;
}

.video-js {
  background-color: transparent;
}

.video-js:before {
  content: '';
  display: block;
  position: absolute;
  left: 1px;
  top: 1px;
  right: 1px;
  bottom: 1px;
  background-color: #000;
}

.vjs-tech {
  visibility: hidden;
}

 .vjs-has-started .vjs-tech {
  visibility: visible;
}
